import Image from 'next/legacy/image';

import { H2, H4 } from './headline';
import { Section } from './Section';

import { TakeOurQuizCTA } from '../components/Hero/TakeOurQuizCTA';

import blob from '@public/img/home-page/blob.svg';
import handBlob from '@public/img/home-page/hand-blob.png';
import riskFreeTrial from '@public/img/home-page/risk-free-trial.png';

type Props = {
  version: 'A' | 'B';
  utmMedium?: string;
  utmCampaign?: string;
};

export const SignPost = ({
  version,
  utmMedium,
  utmCampaign,
}: Props): JSX.Element => {
  if (version === 'A') {
    return (
      <Section backgroundColour="bg-purple">
        <div className="container m-auto lg:flex">
          {/* Image should be on the left of the component for large screen devices */}
          <div className="-mb-1 hidden items-end lg:ml-[-114px] lg:block xl:ml-0">
            <Image
              src={handBlob}
              alt="Hand holding phone"
              width={708}
              height={625}
            />
          </div>
          <div className="px-4 lg:max-w-[606px]">
            <div className="pb-4 pt-16 text-center text-blue lg:text-left">
              <H2>
                Make losing weight feel
                <span className="text-mono-white"> Second Nature</span>
              </H2>
            </div>
            <div className="pb-6 text-center text-blue lg:text-left">
              <H4>
                The first step on your Second Nature journey is to take our
                health quiz.
              </H4>
            </div>
            <div className="flex justify-center pb-2.5 lg:max-w-[196px] lg:justify-start ">
              <TakeOurQuizCTA
                location="Sign post"
                utmMedium={utmMedium}
                utmCampaign={utmCampaign}
              />
            </div>
          </div>

          {/* Image should be at the bottom of the component for mobile devices */}
          <div className="ml-[-160px] flex items-end justify-center lg:hidden">
            <Image
              src={handBlob}
              width={531}
              height={469}
              alt="Hand holding phone"
            />
          </div>
        </div>
      </Section>
    );
  }
  return (
    <Section backgroundColour="bg-purple">
      <div className="relative">
        <div className="bg-purple px-4 pt-[104px] lg:h-[625px] lg:pt-[78px] xl:pt-[178px]">
          {/* STAMP IMAGE */}
          <div className="absolute right-0 top-1 lg:hidden">
            <Image
              src={riskFreeTrial}
              height={159}
              width={140}
              layout="intrinsic"
              alt="Risk free trial"
            />
          </div>
          <div className="pb-4 text-center text-blue lg:px-[284px]">
            <H2>
              Make losing weight feel
              <span className="text-mono-white"> Second Nature</span>
            </H2>
          </div>
          <div className="pb-6 text-center text-blue lg:px-[348px]">
            <H4>
              The first step on your Second Nature journey is to take our health
              quiz.
            </H4>
          </div>
          <div className="m-auto flex flex-row justify-center pb-[86px] md:w-fit lg:pb-[0px]">
            <TakeOurQuizCTA
              location="Sign post"
              utmMedium={utmMedium}
              utmCampaign={utmCampaign}
            />
          </div>
          {/* BLOB IMAGE */}
          <div className="absolute left-[-10px] top-[175px] hidden overflow-hidden lg:inline-block lg:h-[500px]">
            <Image src={blob} width={450} height={450} alt="Blob" />
          </div>
        </div>
      </div>
    </Section>
  );
};
